import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Sidebar from './components/Sidebar';

export default function CreatePost() {
  const [name, setName] = useState('');
  const [author, setAuthor] = useState('');
  const [date, setDate] = useState('');
  const [blocks, setBlocks] = useState([]);
  const [redirect, setRedirect] = useState(false);

  // Handle adding a new block (text or image)
  const addBlock = (type) => {
    const newBlock = type === 'text'
      ? { type: 'text', content: '' }
      : { type: 'image', src: '' };
    setBlocks([...blocks, newBlock]);
  };

  // Handle block content changes
  const updateBlock = (index, newContent) => {
    const updatedBlocks = [...blocks];
    updatedBlocks[index] = { ...updatedBlocks[index], ...newContent };
    setBlocks(updatedBlocks);
  };

  // Handle drag-and-drop reordering
  const reorderBlocks = (result) => {
    if (!result.destination) return; // Dropped outside the list
    const reorderedBlocks = Array.from(blocks);
    const [movedBlock] = reorderedBlocks.splice(result.source.index, 1);
    reorderedBlocks.splice(result.destination.index, 0, movedBlock);
    setBlocks(reorderedBlocks);
  };

  // Handle form submission
  async function createNewPost(ev) {
    ev.preventDefault();

    const data = {
      name,
      author,
      date,
      content: blocks,
    };

    try {
      // const response = await fetch('http://localhost:4000/post', {
      const response = await fetch('https://sope-backend.vercel.app/post', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setRedirect(true);
        alert('Blog post successfully created');
      } else {
        alert('Failed to create blog post');
      }
    } catch (error) {
      console.error('Error creating blog post:', error);
      alert('An error occurred while creating the blog post');
    }
  }

  if (redirect) {
    return <Navigate to={'/admin/blog'} />;
  }

  return (
    <div className="flex">
      <Sidebar />
      <div className="w-full">
        <div className="px-5 py-4 bg-black text-white">
          <p className="capitalize text-lg" style={{ fontFamily: 'Muli' }}>
            Welcome, Admin
          </p>
        </div>
        <div className="p-6">
          <form onSubmit={createNewPost}>
            <input
              className="border border-solid border-black px-5 py-3 w-full"
              style={{ fontFamily: 'Muli' }}
              type="text"
              placeholder="Name"
              value={name}
              onChange={(ev) => setName(ev.target.value)}
            />
            <br />
            <br />
            <input
              className="border border-solid border-black px-5 py-3 w-full"
              style={{ fontFamily: 'Muli' }}
              type="text"
              placeholder="Category"
              value={author}
              onChange={(ev) => setAuthor(ev.target.value)}
            />
            <br />
            <br />
            <input
              className="border border-solid border-black px-5 py-3 w-full"
              style={{ fontFamily: 'Muli' }}
              type="text"
              placeholder="Date"
              value={date}
              onChange={(ev) => setDate(ev.target.value)}
            />
            <br />
            <br />
            <div className="flex space-x-4 mb-4">
              <button
                type="button"
                onClick={() => addBlock('text')}
                className="bg-gray-800 text-white px-4 py-2"
              >
                Add Text Block
              </button>
              <button
                type="button"
                onClick={() => addBlock('image')}
                className="bg-gray-800 text-white px-4 py-2"
              >
                Add Image Block
              </button>
            </div>
            <DragDropContext onDragEnd={reorderBlocks}>
              <Droppable droppableId="blocks">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="space-y-4"
                  >
                    {blocks.map((block, index) => (
                      <Draggable
                        key={index}
                        draggableId={`block-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="border border-dashed border-gray-400 p-4"
                          >
                            {block.type === 'text' ? (
                              <textarea
                                className="w-full border border-solid border-gray-400 p-2"
                                placeholder="Write text here..."
                                value={block.content}
                                onChange={(ev) =>
                                  updateBlock(index, { content: ev.target.value })
                                }
                              />
                            ) : (
                              <div>
                                <input
                                  type="file"
                                  onChange={(ev) =>
                                    updateBlock(index, {
                                      src: URL.createObjectURL(ev.target.files[0]),
                                    })
                                  }
                                />
                                {block.src && (
                                  <img
                                    src={block.src}
                                    alt="Preview"
                                    className="mt-2 max-w-full"
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <button
              className="mt-5 bg-black text-white px-5 py-3 uppercase text-sm transition hover:bg-gray-900"
              style={{ fontFamily: 'Muli' }}
              type="submit"
            >
              Create Post
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
